import React from "react"
import HeadingM from "../../components/shared/HeadingM"
import Schedule from "../../components/shared/program/Schedule"

const program = () => {
  const items = [
    {
      day: "火",
      contents: "リズム",
    },
    {
      day: "水",
      contents:
        "サッカー　/　エクササイズ",
    },
    {
      day: "木",
      contents:
        "リズム　/　エクササイズ",
    },
    {
      day: "金",
      contents:
        "ヨガ",
    },
    { day: "土", contents: "AM　リズム\nPM　サッカー　/　外出活動(買い物、公園等)" },
  ]
  return (
    <div>
      <HeadingM title="スケジュール" target="noone" />
      <Schedule items={items} target="noone" />
    </div>
  )
}

export default program
