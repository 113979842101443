import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import ActivityExample from "../shared/program/ActivityExample"
import HeadingM from "../shared/HeadingM"

const query = graphql`
  query {
    yoga: file(relativePath: { eq: "noone/activity/yoga.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    exercise: file(relativePath: { eq: "noone/activity/exercise.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    rhythm: file(relativePath: { eq: "noone/activity/rhythm.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    soccer: file(relativePath: { eq: "noone/activity/soccer.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
    outing: file(relativePath: { eq: "noone/activity/outing.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, height: 500)
      }
    }
  }
`

const ProgramActivityExample = () => {
  const data = useStaticQuery(query)
  const items = [
    {
      title: "ヨガ",
      description:
        "子どもの発達支援に特化したヨガを学んだインストラクター兼保育士がそれぞれのお子様の発達に合わせて寄り添いながら支援を行います。様々なポーズを楽しむことで、自然と前庭感覚や固有感覚を育てたり、言語指示理解や視覚支援、眼球運動の練習になります。ポーズや呼吸法、キッズ瞑想を通して、普段意識が外側に向きがちな子どもたちの、自分自身を知ること、認めることから始まる子どもの成長を一緒に応援していきます。",
      image: getImage(data.yoga),
    },
    {
      title: "エクササイズ",
      description:
        "Aim high(エイムハイ)フィットネスジムのトレーナーさんとオンラインでの活動になります。ボールやカラーマーカー、フラフープなどを用いたり、毎回運動の内容が変わります。楽しみながら運動をすることは第一ですが、相手の話を聞く、友達と協力する、ルールがある遊びの中での勝敗に対する気持ちなど、社会性も育むことを目的に支援しております。",
      image: getImage(data.exercise),
    },
    {
      title: "リズム運動",
      description:
        "ピアノの伴奏に合わせて全身を動かし、体をしっかりと使うことを目的としています。音楽に合わせ、跳んだり、走ったり、寝転んだりと楽しみながら体を動かすことで、必要な筋肉がつき、運動能力が発達します。\n自分のイメージ通りに体を操れるようになることにより運動以外の様々なことにも自信が持てるよう支援していきます。",
      image: getImage(data.rhythm),
    },
    {
      title: "サッカー",
      description:
        "サッカー（スポーツ）を通して楽しみながら「脳の働きのアンバランス改善」「コミュニケーション能力の向上」「集団ルールの理解」などの療育に大切なポイントを満たすことが目的として行われている療育です。スポーツの中でもサッカーはルールがシンプルでチームとしての目的意識が芽生えやすく、参加した子どもたちの達成感や協調性を養うことできます。",
      image: getImage(data.soccer),
    },
    {
      title: "行事、外出活動等",
      description:
        "お出かけ・季節に合わせた行事を行っておりりんご狩り、山登り、ピクニック、初詣、夏祭りなどお子さまがより楽しめるような行事計画をしています。",
      image: getImage(data.outing),
    },
  ]
  return (
    <div>
      <HeadingM title="活動事例" target="noone" />
      <ActivityExample items={items} />
    </div>
  )
}

export default ProgramActivityExample
